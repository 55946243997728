export const socialIcon = {
  facebook: "ri-facebook-circle-fill",
  instagram: "ri-instagram-fill",
  twitter: "ri-twitter-fill",
  linkedin: "ri-linkedin-fill",
  email: "ri-mail-fill",
  phone: "ri-phone-fill",
  whatsapp: "ri-whatsapp-fill",
  bulb: "ri-lightbulb-flash-fill",
};
