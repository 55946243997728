const detail = [
    {
        image:"assets/indoor.png",
        name:"Indoor Products",
        description:"● Ceiling Fan\n● Table Fan\n● Wall Fan\n● BLDC Fan ",
    },
    {
        image:"assets/outdoor.png",
        name:"Outdoor Products",
        description:"● Street Light\n● Flood Light\n",
    },
    
]
export default detail;