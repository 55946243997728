const detail2 = [
    {
        image:"assets/logo1.png",

    },
    {
        image:"assets/logo1.png",
    
    },
    {
        image:"assets/logo1.png",

    },
]
export default detail2;